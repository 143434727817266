.footer {
  display: flex;
  /* background: #fafafa; */
  box-shadow: inset 0px 0.5px 0px #f4f4f4;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.padding {
  padding: 4.69vw;
  flex-direction: column;
}
.footer-text{
  display: flex;
}
.footer-text a {
  text-decoration: none;
  color: #444;
  font-weight: 700 !important;
  padding: 8px 16px;
}

.footer-text a:focus{
  color: #CA2128 !important
}
.social_media_icons {
  transition: 1s ease ;
}
.social_media_icons:hover{
transform: scale(1.5);
}

@media only screen and (min-width: 1367px) {
  .social_media_icons>SVG{
    width:1.76vw;
     height:1.76vw;
  }
  .footer-text a {
    padding: 0.59vw 1.18vw !important;
  }
}
@media only screen and (max-width: 580px) {
  .footer-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-text-padding {
    margin-right: unset !important;
  }
  .padding {
    padding: 35px 0;
}
.footer-text a{
  padding: 10px;
}
}
