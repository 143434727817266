.container-header {
  width: 85%;
  margin: auto;
  padding-top: 15px;
  margin-bottom: 80px;
}

.header-title {
  width: 60%;
}

.header-desc {
  color: #777777;
  width: 100%;
  margin-top: 20px;
}

.images-container {
  display: flex;
  width: 100%;
}
.header-imageOne {
  width: 69%;
  height: 512px;
}
.header-imageTwo,
.header-imageThree {
  height: 250px;
}
.images-sub-container {
  margin-left: 11px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page-content {
  background-color: #fbe9ea;
  margin-top: 80px;
}

.page-content-desc {
  padding: 50px 200px;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  width: 100%;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.01em;
  color: #ca2128;
}

.team-container {
  width: 85%;
  margin: auto;
}

.team-container-title {
  letter-spacing: -0.025em;
  text-align: center;
}

.team-container-desc {
  text-align: center;
  margin-top: 16px;
  color: #777777;
  margin-bottom: unset;
}

.team-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin-top: 48px;
}

.person-card {
  width: 250px;
  margin: 20px;
}
.person-name {
  margin-bottom: unset;
}

.flex {
  display: flex;
  width: 100%;
}
.person-img {
  border-radius: 50%;
  height: 150px !important;
  width: 150px !important;
}

.person-role {
  color: #ca2128;
  margin-bottom: unset;
}

.marginTop10 {
  margin-top: 10px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginTop15 {
  margin-top: 15px;
}

.text-center {
  text-align: center;
}

.marginBottom80 {
  margin-bottom: 80px;
}

.marginTop96 {
  margin-top: 96px;
}

@media only screen and (max-width: 500px) {
  .container-header {
    width: 85%;
  }
}
@media only screen and (max-width: 768px) {
  .header-title,
  .header-desc {
    width: 100% !important;
    height: unset;
  }
  .images-container {
    flex-direction: column;
  }
  .header-imageOne,
  .header-imageTwo,
  .header-imageThree {
    width: 100%;
    height: 35vh;
    margin-bottom: 10px;
  }
  .images-sub-container {
    margin-left: unset;
    width: 100%;
  }
  .page-content-desc {
    padding: 80px;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.01em;
  }
}

@media only screen and (max-width: 1024px) {
  .page-content-desc {
    padding: 50px;
  }
  .media-icons {
    display: flex;
    justify-content: center;
  }
  .header-title {
    width: 80% !important;
  }
}

@media (min-width:1367px) {
  .container-header{
    padding-top: 1.1vw !important;
    margin-bottom: 5.85vw !important;
  }
  .marginBottom80 {
    margin-bottom: 5.85vw !important;
  }
  .header-title, .header-title>span{
    font-size: 3.52vw !important;
  }
  .header-desc{
    font-size: 1.18vw !important;
    margin-top: 1.5vw !important;
  }
  .header-imageOne{
    height: 37.49vw!important;
  }
  .images-sub-container {
    margin-left: 0.805vw !important;
  }
  .header-imageTwo, .header-imageThree{
    height: 18.30vw !important;
  }
  .page-content{
    margin-top: 5.85vw !important;
  }
  .page-content-desc{
    padding: 3.67vw 14.64vw !important;
    font-size: 1.76vw !important;
    line-height: 2.64vw !important;
  }
  .marginTop96{
    margin-top: 7.02vw !important;
  }
  .team-container-desc{
    margin-top: 1.18vw !important;
  }
  .team-images-container{
    margin-top: 3.52vw !important;
  }
  .person-card{
    width: 18.3vw !important;
    margin: 1.5vw !important;
  }
  .person-img{
    width: 10.99vw !important;
    height: 10.99vw !important;
  }
  .marginTop15{
    margin-top: 1.09vw !important;
  }
  .marginTop10{
    margin-top: 0.732vw !important;
  }
  .media-icons>a>SVG{
    width: 1.76vw !important;
    height: 1.76vw !important;
  }
  .marginRight10{
    margin-right: 0.732vw !important;
  }
}
