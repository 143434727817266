.background {
  display: flex;
  /* height: 65vh; */
  margin-top: 5%;
}
.iphone-image {
  height: 90%;
  width: 100%;
}
.iphone-cover-image {
  width: 50%;
  display: flex;
  align-items: flex-end;
}
.footer-banner-container {
  width: 85%;
  padding-top: 1%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 0;
}

/* .applestr-btn:hover{
  border: 1px solid #000;
}
.playstr-btn {
  border: 1px solid #000;
} */
.banner-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.download-btn {
  border-radius: 0.45vw;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ca2128;
  background: #fff;
  padding: 1.03vw 0px;
  width: 100%;
  display: none;
}

.playstore-btns {
  width: 85%;
  margin: 0 auto;
  display: flex;
  /* justify-content: space-evenly; */
  float: left;
}
.marginRight8{
  margin-right: 8px;
}

@media only screen and (max-width: 877px) {
  .footer-banner-container{
    width: 90%;
    flex-direction: column;
  }
  .banner-content{
    width: 100%;
    margin-top: 10%;
    /* margin-bottom: 10%; */
  }
  .iphone-cover-image {
    margin: 10% auto !important;
    width: 50% !important;
    margin-bottom:0 !important;
  }
}
@media only screen and (max-width: 1024px){
  .footer-banner-container{
    width: 90%;
    margin: 0 auto;
  }
  .playstore-btns{
    margin: unset;
  }
  .iphone-cover-image{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .iphone-image{
    width: 100%;
    height: 90%;
    object-fit: unset;
  }
}
@media only screen and (max-width: 1024px) {
  .background{
    height: unset;
  }
}
@media only screen and (max-width: 768px) {
  .iphone-cover-image {
    display: none;
  }
  /* .banner-content {
    width: 85%;
  } */
  .background {
    height: unset !important;
    padding: 1.76vw 0;
  }
  .playstore-btns {
    display: none !important;
  }
  .download-btn {
    display: block;
  }
  .footer-banner-container {
    width: 85%;
  }
  .banner-content {
    width: 100%;
  }
}

@media (min-width: 1367px) {
  .playstore-btns>SVG{
    width: 11.86vw !important;
    height: 3.52vw !important;
  }
  .marginRight8{
    margin-right: 0.59vw !important;
  }
  .banner-title {
    font-size: 2.05vw !important;
  }
  .download-btn{
    font-size: 1.18vw !important;
    line-height: 1.76vw !important;
  }
}
