.section6-margin {
  width: 100%;
  text-align: center;
}
.qtns-container{
  padding-top: 4%;
  background-color: #fafafa;
  padding-bottom: 4%;
}
@media only screen and (max-width: 877px) {
  .qtns-container{
    margin-top: 10% !important;
    margin-bottom: 10%;
  }
  .section6-margin{
    margin-bottom: 10% !important;
    width: 90%;
    margin: auto;
  }
  .qtns-container{
    width: 90%;
    margin: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .qtns-container {
    margin-top: 10% !important;
    /* margin-bottom: 10%; */
  }
}
@media only screen and (max-width: 580px) {
  .section6-margin {
    margin-left: 16px;
  }
  /* .qtns-container {
    display: none !important;
  } */
}
