.blog_page_container{
    width: 90%;
    margin: auto;
    padding-top: 15px;
    z-index: 1;
    /* margin-top: -12%; */
}
.blog_page_header{
    /* height: 65vh; */
    padding: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 4%;
    background: rgba(251, 233, 234, 0.5);
}
.blogs_page_section{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

}
.blogs_page_card{
    margin-bottom: 10%;
    width: 30%;
}

@media (max-width: 877px) {
    .blogs_page_section{
        flex-direction: column;
    }
    .blogs_page_card{
        width: 100%;
    }
    .blog_page_header{
        height: unset;
        padding: 10% 0;
    }
}
@media (min-width: 1367px) {
    .blog_page_container{
        padding-top: 1.09vw !important;
    }
    .padding20{
        padding: 1.5vw !important;
    }
    .card-subtitle{
        margin-bottom: 0.59vw !important;
    }
    .card-title{
        font-size: 1.5vw !important;
        margin-bottom: 0.89vw !important;
    }
    .card-text{
        font-size: 1.18vw !important;
        margin-bottom: 1.18vw !important;
    }
}
