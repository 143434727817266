.title-caption {
    font-size: 18px;
    font-style: normal;
    font-family: Inter;
    font-weight: normal;
    margin-top: 12px;
    line-height: 26px;
    color: #777777;
}
.title-section{
    width: 100%;
}
.blogs_section_header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: baseline;
    text-align: center;
    padding: 20px 0;
    margin-bottom: 4%;
}
.blogs_container {
flex-direction: column;
width: 90%;
margin: auto;
margin-top: 4%;
}
.author-section{
    width: 100%;
    display: flex;
    align-items: center;
}
.author-title {
    color: #111111 !important;
    font-size: 14px;
    padding-bottom: 5px;
}
.fontsize14px{
    font-size: 14px;
}
.author-section>img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.author-section>div{
    padding-left: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.author-section>div>p{
    margin: 0;
    color: #777777;
    font-weight: 400;
}
.btn-outline-custom {
    color: #CA2128;
    background-color: transparent;
    background-image: none;
    border-color: #E2E2E2;
}
.btn-outline-custom:hover {
    color: #fff;
    background-color: #CA2128;
    border-color: #CA2128;
}
.react-multi-carousel-item {
    padding: 0px 10px;
    /* width: 29.7vw !important; */
}
.marginBottom8{
    margin-bottom: 8px;
}
.marginBottom12{
    margin-bottom: 12px;
}
.marginBottom16{
    margin-bottom: 16px;
}
.blogs_section {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
@media (max-width: 877px) {
    .blogs_section_header{
        flex-direction: column;
    }
    .blogs_section{
        flex-direction: column;
    }
    .blogs_section_card{
        width: 100%;
        margin-bottom: 10%;
    }
}
@media (min-width: 1367px) {
    .blogs_section_header{
        padding: 1.5vw 0 !important;   
    }
    .title-caption{
        margin-top: 0.89vw !important;
    }
    .react-multi-carousel-item{
        padding: 0px 0.74vw !important;
        width: 29.7vw !important;
    }
    .marginBottom8{
        margin-bottom: 0.59vw !important;
    }
    .marginBottom12{
        margin-bottom: 0.89vw !important ;
    }
    .marginBottom16{
        margin-bottom: 1.18vw !important;
    }
    .author-section>img {
        width: 2.93vw !important;
        height: 2.93vw !important;

    }
    .author-title {
        font-size: 1.03vw !important;
    }
    .author-section>div{
        padding-left: 0.89vw;
        font-weight: 500;
        font-size: 1.03vw;
        line-height: 1.5vw;
    }
}