.background-container > header,
.codeconvey-top {
  font-family: "Inter", sans-serif;
}
.section1-title {
  border: 1px solid #fff;
    padding: 10px 25px;
    line-height: 4.5vw;
    border-radius: 10px;
    font-style: italic;
}


.background-container {
  height: calc(100vh - 65px);
  width: 100% ;
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.description-video {
  color: #fff;
  position: absolute;
  bottom: 0;
  display: flex;
  font-size: 4.5vw;
  font-weight: 700;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 3% !important;
  padding: 32px;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.chat-whatsapp {
  background-color: transparent;
  right: 0;
  position: fixed;
  bottom: 0;
  padding-right: 4px;
  padding-bottom: 32px;
  z-index: 1;
  cursor: pointer;
}
@media screen and (min-width: 600px) {
  .chat-whatsapp {
      padding-right: 30px;
  }
}

@media (max-width: 877px) {
  .background{
    height: unset !important;
  }
}
@media (min-width: 992px) {

}
/* @media (min-width: 350px) {
  .background-container {
    width: 970px !important;
  }
} */
// @media (min-width: 480px) {
//   .background-container {
//     width: 400px;
//   }
// }
@media (min-width: 1367px) {
  .background-container {
    // width: 100%;
    height: calc(100vh - 4.7vw);
  }
}
.orange {
  background: #ffffff;
}
.circle {
  background-color: white;
  z-index: 999;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow: 0px 0px 1px 1px #ffffff;
}

.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255);
  }
  50% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2);
  }
}
video#videobg {
  object-fit: fill;
  width: 100%;
  height: 100%;
}
.content {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
}
.notified_btn {
  background: #ffffff;
  color: #ca2128;
  white-space: nowrap;
  margin: 0px 16px;
  border: none;
  padding: 14px 24px;
  text-align: center;
  cursor: pointer;
  width: 158px;
  // height: 10vh;
  width: 100%;
  font-size: 26px;
  font-weight: 700;
  margin-top: 30%;
  border: 1px solid #ca2128;
  outline: 5px auto -webkit-focus-ring-color;
  border-radius: 1%;
}
.notified_btn:hover{
  background: #ca2128;
  color: #fff;
}
.notified_btn:active {
  transform: scale(0.98);
}
.main-section {
  height: 1000px !important;
}
.banner-image {
  object-fit: cover;
  height: auto;
  width: 100%;
}
.cover-image {
  width: 1000px;
}
.test {
  width: 540px;
  background-color: white;
  padding: 24px;
  z-index: 100;
  margin-left: 300px;
  border-radius: 12px;
}
.mainTest {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  background-image: url("./../../../assets/Banner_image.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right;
  background-size: 800px;
  height: 800px;
}
.test_notified_btn {
  background: #ca2128;
  color: #ffffff;
  white-space: nowrap;
  border: none;
  border-radius: 6px;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  width: 158px;
}
.main_test {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  color: #111111;
}
.sub-test {
  margin-top: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Body/$body-3 */

  color: #777777;
}

@mixin rings($duration, $delay) {
  opacity: 0;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border: 43px solid rgb(255, 255, 255);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  // z-index: -1;
}
@media (max-width: 877px) {
  video#videobg{
    object-fit: fill;
  }
}

.circle-ripple {
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 11px;
  width: 11px;
  margin: 0 auto;
  transform: translateZ(50px);
  // border-radius: 100%;
  background: #276ef1;
  box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  animation: ripple 2s linear infinite;
}
// $color: #65ff78;
$color: #276ef1;
@keyframes ripple {
// ----------------- mock up -------------------

0% {
  box-shadow: 0 0 0 0 rgba($color, 0.3),
              0 0 0 0.5em rgba($color, 0.3),
}
100% {
  box-shadow: 0 0 0 1em rgba($color, 0.3),
              0 0 0 2em rgba($color, 0.3),
}



  // -------------------- not mockup ----------------------
  // 0% {
  //   box-shadow: 0 0 0 0 rgba($color, 0.3),
  //               0 0 0 1em rgba($color, 0.3),
  //               0 0 0 3em rgba($color, 0.3),
  //               0 0 0 5em rgba($color, 0.3);
  // }
  // 100% {
  //   box-shadow: 0 0 0 1em rgba($color, 0.3),
  //               0 0 0 3em rgba($color, 0.3),
  //               0 0 0 5em rgba($color, 0.3),
  //               0 0 0 8em rgba($color, 0);
  // }
  // ------------------ previous --------------------------
  // from {
  //   opacity: 0.5;
  //   transform: scale3d(0.75, 0.75, 1);
  // }

  // to {
  //   opacity: 0.2;
  //   transform: scale3d(1.5, 1.5, 1);
  // }
}

@media (min-width:1367px) {
  .notified_btn{
    font-size: 2vw !important;
    padding: 1vw 2vw !important;
    height: 4.7vw !important;
  }
}
