/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

html{
  scroll-behavior: smooth;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.MuiDialogTitle-root{
  padding-bottom: 0 !important;
}
.MuiDialogContent-root{
  padding-top: 0 !important;
}
@media (min-width: 877px){
  .MuiDialog-paperScrollPaper{
    max-height: unset !important;
  }
}

@media (min-width: 1367px) {
.fontsize14px{
  font-size: 1.03vw !important;
}
.fontsize16px{
  font-size: 1.18vw !important;
}
.fontsize18px{
  font-size: 1.32vw !important;
}
.fontsize20px{
  font-size: 1.5vw !important;
}
.fontsize24px{
  font-size: 1.76vw !important;
}
.fontsize30px{
  font-size: 2.2vw !important;
}
.fontsize36px{
  font-size: 2.64vw !important;
}
.fontsize48px {
  font-size: 3.52vw !important;
}
.fontsize62px{
  font-size: 4.54vw !important;
}
.lineheight62px{
  line-height: 4.54vw !important;
}
.lineheight32px{
  line-height: 2.35vw !important;
}
.lineheight26px{
  line-height: 1.91vw !important;
}
.lineheight40px{
  line-height: 2.93vw !important;
}
.lineheight20px{
line-height: 1.5vw !important;
}
.lineheight60px{
  line-height: 4.40vw !important;
}
.react-multiple-carousel__arrow{
  min-width: 3.15vw !important;
  min-height: 3.15vw !important;
}
.react-multiple-carousel__arrow::before{
  font-size: 1.5vw !important;
}
.Dropdown-option{
  font-size: 1.18vw !important;
}
}

